import * as React from "react";
import Layout from "../../layout/Layout";
import { Router, RouteComponentProps } from "@reach/router";
import { useEffect, useState } from "react";
import axios, { AxiosResponse } from "axios";

const participation = ({}) => {
  return (
    <Layout title="Register Participation">
      <Router basepath="/participation">
        <RegisterWithCode path="/:sharedKey" />
        <Home path="/" />
      </Router>
    </Layout>
  );
};

export default participation;

interface SuccessMessage {
  participant: {
    firstName: string;
    lastName: string;
  };
  success: boolean;
  session: number;
}

const RegisterWithCode = (
  props: RouteComponentProps<{ sharedKey: string }>
) => {
  const [email, setEmail] = useState("");
  const [storeEmail, setStoreEmail] = useState(true);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<null | SuccessMessage>(
    null
  );
  const sharedKey = props.sharedKey || "";
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await submitParticipation(email);
  };

  const submitParticipation = async (emailInput: string) => {
    try {
      setLoading(true);
      const res: AxiosResponse<SuccessMessage> = await axios.post(
        "https://api.nordictraumarad.com/api/session/register",
        { email: emailInput, sharedKey }
      );
      console.log(res.data);
      if (res.data?.success) {
        if (storeEmail) {
          localStorage.setItem("email", emailInput);
        }
        setErrorMessage(null);
        setLoading(false);
        setSuccessMessage(res.data);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
      localStorage.removeItem("email");
      setErrorMessage(
        "Error: Make sure that you have the right session code and email"
      );
    }
  };

  useEffect(() => {
    const emailFromLocal = localStorage.getItem("email");
    if (emailFromLocal) {
      setEmail(emailFromLocal);
      submitParticipation(emailFromLocal);
    }
  }, [setEmail]);

  if (successMessage) {
    return (
      <div className="text-gray-200">
        <h2 className="text-4xl text-center font-light uppercase">
          Register participation
        </h2>
        <div className="py-4 px-12 flex flex-col justify-center mx-auto max-w-lg bg-gray-800 mt-6 text-center">
          <h3 className="text-lg">
            {successMessage.participant.firstName}{" "}
            {successMessage.participant.lastName}
          </h3>
          <h2 className="text-xl text-green-500">
            Your participation has now been registered for session{" "}
            {successMessage.session}
          </h2>
        </div>
      </div>
    );
  }

  return (
    <div className="text-gray-200">
      <h2 className="text-4xl text-center font-light uppercase">
        Register participation
      </h2>
      <form action="post" onSubmit={handleSubmit}>
        <div className="py-4 px-12 flex flex-col justify-center mx-auto max-w-lg bg-gray-800 mt-6">
          <label className="text-center text-lg" htmlFor="email">
            Email
            <br />
            <span className="text-sm">
              Verify that it is the same you used when you registered
            </span>
          </label>
          <input
            type="email"
            name="email"
            className="mt-2 px-6 py-2 text-gray-800"
            value={email}
            onChange={(e) => setEmail(e.currentTarget.value)}
          />
          <label htmlFor="storeEmail" className="mx-auto mt-1">
            Remember me
            <input
              type="checkbox"
              name="storeEmail"
              id="storeEmail"
              checked={storeEmail}
              className="ml-3"
              onChange={(e) => setStoreEmail(e.currentTarget.checked)}
            />
          </label>
          <button
            type="submit"
            className="px-3 py-2 bg-red-800 rounded hover:bg-red-900 mt-3"
            disabled={loading}
          >
            {loading ? "Loading..." : "Submit"}
          </button>
          <div className="text-red-400 text-center h-4 mt-2 text-md">
            {errorMessage}
          </div>
        </div>
      </form>
    </div>
  );
};

const Home = (props: RouteComponentProps) => {
  return <h2 className="text-4xl text-center font-light uppercase">Home</h2>;
};
